import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from '../core/Http/http-client-helper.service';

import { CitGlobalContantsService } from '../core/globalUrl/cit-global-contants.service';

@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(
        private httpClient: HttpClientHelperService,
        private globalConst: CitGlobalContantsService
    ) { }

    searchType(){
  
    }
      
    getmenusList(page,query) {
      if(query == ''){
        return this.httpClient.getRequest(this.globalConst.menusList + '?page=' + page);
      }else{
        return this.httpClient.getRequest(this.globalConst.menusList + '?search=' + query + '&page=' + page);
      }
       
      } 
      
    getmenusListFilter(id,page) {
          return this.httpClient.getRequest(this.globalConst.menusList + '?category_id=' + id + '&page=' + page);
         
        }  
      
    getMenuById(id) {
        return this.httpClient.getRequest(this.globalConst.menusList + '/' + id);
      }  
      
    menuCreate(data) {
        return this.httpClient.postRequest(this.globalConst.menusList, data);
      } 
      
   
    menuUpdate( menu_id,data) {
        return this.httpClient.putRequest(this.globalConst.menusList + '/' + menu_id ,data);
      }


    menuDelete( menu_id) {
        return this.httpClient.deleteRequest(this.globalConst.menusList + '/' + menu_id, {});
      }
      
      
    getCategories(page,query) {
      if(query == ''){
        return this.httpClient.getRequest(this.globalConst.categories + '?page=' + page);
      }else{
        return this.httpClient.getRequest(this.globalConst.categories + '?search=' + query + '&page=' + page);
      }
      }  
      
    getAllCategories() {
        return this.httpClient.getRequest(this.globalConst.categories + '/dropdown');
    }  
      
    getCategoryById(id) {
      return this.httpClient.getRequest(this.globalConst.categories + '/' + id);
    }  
      
    categoryCreate(data) {
        return this.httpClient.postRequest(this.globalConst.categories, data);
      }

    categoryUpdate( category_id,data) {
        return this.httpClient.putRequest(this.globalConst.categories + '/' + category_id ,data);
      }


    categoryDelete( category_id) {
        return this.httpClient.deleteRequest(this.globalConst.categories + '/' + category_id, {});
      }  
      
       
    getIcons() {
      return this.httpClient.getRequest(this.globalConst.categories + '/icons', {});
    }


    getStaff(page,query) {
      if(query == ''){
        return this.httpClient.getRequest(this.globalConst.users + '?page=' + page);
      }else{
        return this.httpClient.getRequest(this.globalConst.users + '?search=' + query + '&page=' + page);
      }
   
      }  
      
    getStaffById(id) {
      return this.httpClient.getRequest(this.globalConst.users + '/' + id);
    }  
      
    staffCreate(data) {
        return this.httpClient.postRequest(this.globalConst.users, data);
      }  
      

    staffUpdate(users_id,data) {
      return this.httpClient.putRequest(this.globalConst.users + '/' + users_id ,data);
    }


    staffDelete( users_id) {
      return this.httpClient.deleteRequest(this.globalConst.users + '/' + users_id, {});
    }


    feedback(data) {
      return this.httpClient.getRequest(this.globalConst.feedback, data);
    }
    
    getOrders(from,to,order,query) {
      if(query == ''){
        return this.httpClient.getRequest(this.globalConst.orders + '?from=' + from + '&to=' + to + '&sort=' + order, {});
      }else{
        return this.httpClient.getRequest(this.globalConst.orders + '?from=' + from + '&to=' + to + '&sort=' + order + '&search=' + query , {});
      }
    }
   

    updateOrders(order_id,data) {
      return this.httpClient.putRequest(this.globalConst.orders + '/' + order_id, data);
    }

    deleteOrders(index) {
      return this.httpClient.deleteRequest(this.globalConst.orders + '/' + index, {});
    }
    

    //order tab
    getOrderNew(name) {
      return this.httpClient.getRequest(this.globalConst.orders + '/list/' + name, {});
    }    
    
   

    tabSubmit(order_id,data) {
      return this.httpClient.putRequest(this.globalConst.orders + '/' + order_id, data);
    }


    getSalesOrder(from,to,order,page) {
      return this.httpClient.getRequest(this.globalConst.orders + '/sales?from=' + from + '&to=' + to + '&sort=' + order + '&page=' + page, {});
    }   
    
    
    getDashboard(from,to) {
      return this.httpClient.getRequest(this.globalConst.dashboard + '?from=' + from + '&to=' + to);
    }  

    password(data) {
      return this.httpClient.postRequest(this.globalConst.password, data);
    }
    
    resetPassword(data) {
      return this.httpClient.putRequest(this.globalConst.password, data);
    }
    
    offlineSubmit(id) {
      return this.httpClient.putRequest(this.globalConst.orders + '/complete/' + id, {});
    }
    
    //profile tab
    getProfile() {
      return this.httpClient.getRequest(this.globalConst.profile, {});
    } 

    updateProfile(data) {
      return this.httpClient.postRequest(this.globalConst.profile, data);
    } 
}

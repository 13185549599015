
import { Component, OnInit } from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from "./core/auth/auth.service";
import {LoaderService} from './core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cafe';
  isLoading;

  constructor( 	private loaderService:LoaderService,private router: Router,private authService: AuthService,private titleService: Title,){

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  logout(){
    // this.authService.logOut();
  }

  ngOnInit(){
  }

  

}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser/';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '../login/login.component';
import { PagenotfoundComponent } from '../pagenotfound/pagenotfound.component';
import { LayoutComponent } from '../layout/layout/layout.component';
import { ForgotPasswordComponent } from '../forgotpassword/forgotpassword.component';
import { ResetComponent } from '../reset/reset.component';

// Import canActivate guard services
import { AuthGuard } from '../core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: '../layout/layout/layout.module#LayoutModule'
      }], canActivate: [AuthGuard]
  },


  { path: '', redirectTo: '/login', pathMatch: 'full' },

  { path: 'login', component: LoginComponent },

  { path: 'reset', component: ResetComponent },

  { path: 'forgot-password', component: ForgotPasswordComponent },

  { path: '**', component: PagenotfoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: "enabled"}), CommonModule, BrowserModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }

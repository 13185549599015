import { Component, OnInit, ViewChild,HostListener  } from '@angular/core';
import { AuthService } from '../../core/auth/auth.service';
import { LayoutService } from '../../layout/layout.service';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
  import { SharedService } from '../../core/services/data.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}


@HostListener('window:resize', ['$event'])
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  userStatus;
  appData;
  img;
  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    public sharedService: SharedService,
  ) { 

    // this.userStatus = this.authService.userStatus;
  }

 // custom scroll
  public disabled = false;

  public config: PerfectScrollbarConfigInterface = {};

  // @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

 // custom scroll end

  menuItems: any[];
  bodyClasses = 'sidebar-mini';
  ngOnInit() {
   
    var existing:any = localStorage.getItem('currentUser');
    existing = existing ? JSON.parse(existing) : {};
    this.img =  existing.data.user.image;
    this.sharedService.profileImage.subscribe(img => {
        var existing:any = localStorage.getItem('currentUser');
        existing = existing ? JSON.parse(existing) : {};
        this.img =  existing.data.user.image;
    })
    
    // console.log(existing)
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  
  hideSidemenu(){
      let targetWidth = 768;
      const bodyClass = document.getElementsByClassName('sidebar-mini');
      const body = document.getElementsByTagName('body')[0];

      if ( window.innerWidth <= targetWidth) {     
        body.classList.remove('sidebar-mini');
        //Add your javascript for screens wider than or equal to 768 here
      }

  }
  
  logout(){
    this.authService.logout();
  }
  
  toggle() {
    const bodyClass = document.getElementsByClassName('sidebar-mini');
    const body = document.getElementsByTagName('body')[0];

    if (bodyClass.length === 0) {
      body.classList.add('sidebar-mini');
    } else {
      body.classList.remove('sidebar-mini');
    }
  }

}

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CitGlobalContantsService {

  // baseUrl
  baseUrl = environment.baseUrl +'/admin';

  //Login
  login: string = this.baseUrl + '/login';

  password: string = this.baseUrl + '/password';
  
  feedback: string = this.baseUrl + '/feedbacks';
  
  orders: string = this.baseUrl + '/orders';

  dashboard: string = this.baseUrl + '/stats';

  users: string = this.baseUrl + '/users';

  categories: string = this.baseUrl + '/categories';

  menusList: string = this.baseUrl + '/menus';

  profile: string = this.baseUrl + '/profile';

  profiles: string = this.baseUrl + '/profiles';

  getByIdUser: string = this.baseUrl + '/users';

  deleteUser: string = this.baseUrl + '/delete';


}
